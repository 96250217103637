@charset "UTF-8";
// -----------------------------------------------------------------
// Footer
// - footerのみ
// -----------------------------------------------------------------

/*	Footer
______________________________________________*/
.l-footer {
	width: 100%;
	margin-top: 200px;
	padding: 0 30px 30px;
	position: relative;
	z-index: 10;
	&.position--fixed {
		margin-top: 0;
		position: fixed;
		bottom: 0;
		left: 0;
	}
	@media #{$querySp} {
		margin-top: 100px;
		padding: 0 15px 15px;
	}
}

.l-footer__copyright {
	color: #666;
	font-size: 1.6rem;
	font-family: $font-min;
	line-height: 1;
	text-align: right;
	@media #{$querySp} {
		font-size: 1.2rem;
	}
}
