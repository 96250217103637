@charset "UTF-8";
// -----------------------------------------------------------------
// Component
// - Layout
// -----------------------------------------------------------------

/*
	content box
--------------------------------------------- */
.c-section {
	width: 100%;
	margin-top: 60px;
	@media #{$queryPcTab} {
	}
}

.c-division {
	width: 100%;
	margin-top: 30px;
	@media #{$querySp} {
		margin-top: 15px;
	}
}
