@charset "UTF-8";
// -----------------------------------------------------------------
// Utility
// - utilityとして使用
// -----------------------------------------------------------------

/*
  SP・PC切替
--------------------------------------------- */
.u-hide {

	&-sp {
		@media #{$querySp} {
			display: none !important;
		}
	}

	&-pc {
		@media #{$queryPc} {
			display: none !important;
		}
	}
}


/*	Font
______________________________________________*/

.u-fw-n { font-weight: normal !important; }
.u-fw-b { font-weight: bold !important; }
.u-fs10 { font-size: 72% ; }
.u-fs11 { font-size: 79% !important; }
.u-fs12 { font-size: 86% !important; }
.u-fs13 { font-size: 93% !important; }
.u-fs14 { font-size: 100% !important; }
.u-fs15 { font-size: 108% !important; }
.u-fs16 { font-size: 115% !important; }
.u-fs17 { font-size: 122% !important; }
.u-fs18 { font-size: 129% !important; }
.u-fs19 { font-size: 136% !important; }
.u-fs20 { font-size: 143% !important; }
.u-fs21 { font-size: 150% !important; }
.u-fs22 { font-size: 158% !important; }
.u-fs23 { font-size: 165% !important; }
.u-fs24 { font-size: 172% !important; }
.u-fs25 { font-size: 179% !important; }
.u-fs26 { font-size: 186% !important; }

.u-ff-serif {
	font-family: $font-min;
}


/*	Color
______________________________________________*/


/*	Align
______________________________________________*/

.u-ta-l { text-align: left !important; }
.u-ta-c { text-align: center !important; }
.u-ta-r { text-align: right !important; }
.u-va-t { vertical-align: top !important; }
.u-va-m { vertical-align: middle !important; }
.u-va-b { vertical-align: bottom !important; }


/*	Float
______________________________________________*/

.u-fl-l { display: inline; float: left; }
.u-fl-r { display: inline; float: right; }
.u-fl-box { overflow: hidden; }


/*	Tools
______________________________________________*/

.u-cf { clear: both; }


/*	margin
______________________________________________*/

.u-mt10 { margin-top: 10px; }
.u-mt20 { margin-top: 20px; }
.u-mt30 { margin-top: 30px; }
.u-mt40 { margin-top: 40px; }
.u-mt50 { margin-top: 50px; }
.u-mt60 { margin-top: 60px; }
.u-mt70 { margin-top: 70px; }
.u-mt80 { margin-top: 80px; }
.u-mt90 { margin-top: 90px; }
.u-mt100 { margin-top: 100px; }

.u-mr10 { margin-right: 10px; }
.u-mr20 { margin-right: 20px; }
.u-mr30 { margin-right: 30px; }
.u-mr40 { margin-right: 40px; }
.u-mr50 { margin-right: 50px; }
.u-mr60 { margin-right: 60px; }
.u-mr70 { margin-right: 70px; }
.u-mr80 { margin-right: 80px; }
.u-mr90 { margin-right: 90px; }
.u-mr100 { margin-right: 100px; }

.u-mb10 { margin-bottom: 10px; }
.u-mb20 { margin-bottom: 20px; }
.u-mb30 { margin-bottom: 30px; }
.u-mb40 { margin-bottom: 40px; }
.u-mb50 { margin-bottom: 50px; }
.u-mb60 { margin-bottom: 60px; }
.u-mb70 { margin-bottom: 70px; }
.u-mb80 { margin-bottom: 80px; }
.u-mb90 { margin-bottom: 90px; }
.u-mb100 { margin-bottom: 100px; }

.u-ml10 { margin-left: 10px; }
.u-ml20 { margin-left: 20px; }
.u-ml30 { margin-left: 30px; }
.u-ml40 { margin-left: 40px; }
.u-ml50 { margin-left: 50px; }
.u-ml60 { margin-left: 60px; }
.u-ml70 { margin-left: 70px; }
.u-ml80 { margin-left: 80px; }
.u-ml90 { margin-left: 90px; }
.u-ml100 { margin-left: 100px; }


/*	padding
______________________________________________*/

.u-pt10 { padding-top: 10px; }
.u-pt20 { padding-top: 20px; }
.u-pt30 { padding-top: 30px; }
.u-pt40 { padding-top: 40px; }
.u-pt50 { padding-top: 50px; }
.u-pt60 { padding-top: 60px; }
.u-pt70 { padding-top: 70px; }
.u-pt80 { padding-top: 80px; }
.u-pt90 { padding-top: 90px; }
.u-pt100 { padding-top: 100px; }

.u-pr10 { padding-right: 10px; }
.u-pr20 { padding-right: 20px; }
.u-pr30 { padding-right: 30px; }
.u-pr40 { padding-right: 40px; }
.u-pr50 { padding-right: 50px; }
.u-pr60 { padding-right: 60px; }
.u-pr70 { padding-right: 70px; }
.u-pr80 { padding-right: 80px; }
.u-pr90 { padding-right: 90px; }
.u-pr100 { padding-right: 100px; }

.u-pb10 { padding-bottom: 10px; }
.u-pb20 { padding-bottom: 20px; }
.u-pb30 { padding-bottom: 30px; }
.u-pb40 { padding-bottom: 40px; }
.u-pb50 { padding-bottom: 50px; }
.u-pb60 { padding-bottom: 60px; }
.u-pb70 { padding-bottom: 70px; }
.u-pb80 { padding-bottom: 80px; }
.u-pb90 { padding-bottom: 90px; }
.u-pb100 { padding-bottom: 100px; }

.u-pl10 { padding-left: 10px; }
.u-pl20 { padding-left: 20px; }
.u-pl30 { padding-left: 30px; }
.u-pl40 { padding-left: 40px; }
.u-pl50 { padding-left: 50px; }
.u-pl60 { padding-left: 60px; }
.u-pl70 { padding-left: 70px; }
.u-pl80 { padding-left: 80px; }
.u-pl90 { padding-left: 90px; }
.u-pl100 { padding-left: 100px; }
