@charset "UTF-8";
// -----------------------------------------------------------------
// Component
// - button
// -----------------------------------------------------------------

/* hamburger menu
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.c-hamburger {
	display: block;
	// width: 50px;
	// height: 34px;
	width: 36px;
	height: 28px;
	@media #{$querySp} {
		width: 25px;
		height: 17px;
	}
}
.c-hamburger__button {
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
	cursor: pointer;
}
.c-hamburger__bar {
	display: block;
	// width: 50px;
	width: 34px;
	height: 2px;
	background: #555;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	transition: transform .2s, opacity .2s;
	&:nth-child(1) {
		// transform: translateY(-16px);
		transform: translateY(-10px);
	}
	&:nth-child(3) {
		// transform: translateY(16px);
		transform: translateY(10px);
	}
	@media #{$querySp} {
		width: 25px;
		height: 1px;
		&:nth-child(1) {
			transform: translateY(-8px);
		}
		&:nth-child(3) {
			transform: translateY(8px);
		}
	}
}
.c-hamburger__button.is-active {
	.c-hamburger__bar {
		&:nth-child(1) {
			transform: rotate(45deg);
		}
		&:nth-child(2) {
			opacity: 0;
		}
		&:nth-child(3) {
			transform: rotate(-45deg);
		}
	}
}

/* slider next / prev
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.c-buttonPrev,
.c-buttonNext {
	width: 60px;
	height: 60px;
	background: #666;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	cursor: pointer;
	opacity: 1;
	transition: opacity .2s;
	&::before {
		content: "";
		display: block;
		width: 16px;
		height: 16px;
		transform: rotate(-45deg);
	}
	&.swiper-button-disabled {
		opacity: 0;
	}
	@media #{$querySp} {
		width: 30px;
		height: 30px;
		&::before {
			width: 8px;
			height: 8px;
		}
	}
}
.c-buttonPrev {
	&::before {
		border-top: 2px solid #fff;
		border-left: 2px solid #fff;
	}
}
.c-buttonNext {
	&::before {
		border-right: 2px solid #fff;
		border-bottom: 2px solid #fff;
	}
}

/* ‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑
	テキストリンク
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.c-linkUnderline {
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
	@media #{$querySp} {
		&:hover {
			text-decoration: underline;
		}
	}
}
