@charset "UTF-8";
// -----------------------------------------------------------------
// About
// -----------------------------------------------------------------

.p-companyName {
	color: #555;
	line-height: 1;
	margin-bottom: 30px;
	@media #{$querySp} {
		margin-bottom: 20px;
	}
}
.p-companyName__logo {
	width: 300px;
	margin-left: -5px;

	@media #{$querySp} {
		width: 100%;
		margin-left: -2px;
	}
}
.p-companyName__en {
	font-family: $font-min;
	font-size: 2.8rem;
	display: block;
	@media #{$querySp} {
		font-size: 2rem;
	}

	+ .p-companyName__ja{
		margin-top: 20px;
		@media #{$querySp} {
			margin-top: 10px;
		}
	}
}
.p-companyName__ja {
	display: block;
	font-size: 2.1rem;
	@media #{$querySp} {
		font-size: 1.5rem;
	}
}
.p-aboutVerticalAjust {
	margin-top: 5px;
	@media #{$querySp} {
		margin-top: 0;
	}
}
.p-locationBlock {
	margin-bottom: 30px;
	@media #{$querySp} {
		margin-bottom: 15px;
	}
}
.p-ceoName {
	color: #555;
	line-height: 1;
}
.p-ceoName__en {
	font-family: $font-min;
	font-size: 2.8rem;
	display: block;
	margin-bottom: 15px;
	@media #{$querySp} {
		font-size: 1.8rem;
		margin-bottom: 10px;
	}
}
.p-ceoName__enPosition {
	font-size: 2rem;
	@media #{$querySp} {
		font-size: 1.3rem;
	}
}
.p-ceoName__ja {
	font-size: 1.8rem;
	font-weight: bold;
	display: block;
	margin-bottom: 30px;
	@media #{$querySp} {
		font-size: 1.3rem;
		margin-bottom: 15px;
	}
}
.p-profileBlock {
	margin-bottom: 60px;
	&:first-of-type {
		margin-top: 30px;
	}
	&:last-child {
		margin-bottom: 0;
	}
	@media #{$querySp} {
		margin-bottom: 30px;
		&:first-of-type {
			margin-top: 15px;
		}
	}
}
.p-profileBlock__headline {
	margin-bottom: 15px;
	@media #{$querySp} {
		margin-bottom: 10px;
	}
}

.p-about{
	*{
		color: $color-black;
	}

	.l-content__container{
		@media #{$queryPc} {
			max-width: 960px;
		}
	}


	.p-about_dl{
		dt{
			font-size: 2.4rem;
			font-family: $font-min;
			@media #{$querySp} {
				font-size: 1.9rem;
			}

			+ dd{
				margin-top: 44px;
				@media #{$querySp} {
					margin-top: 22px;
				}
			}
		}

		dd{
			+ dt{
				margin-top: 80px;
				@media #{$querySp} {
					margin-top: 40px;
				}
			}
		}

		.span{
			font-size: 1.8rem;
			@media #{$querySp} {
				font-size: 1.5rem;
			}
		}
	}

	.p-last_name{
		+ .p-name{
			margin-left: 2em;
		}
	}
	.p-name{
		letter-spacing: 1em;
	}

	p{
		+ .p-table{
			margin-top: 50px;
			@media #{$querySp} {
				margin-top: 25px;
			}
		}
	}

	.c-fontDefault{
		+ .c-fontDefault{
			margin-top: 50px;
			@media #{$querySp} {
				margin-top: 25px;
			}
		}
	}

	.p-table{
		font-size: 1.8rem;
		font-family: $font-min;
		line-height: 1.7;

		@media #{$querySp} {
			font-size: 1.4rem;
		}

		tr {

			td {

				&:first-child {
					width: 114px;

					@media #{$querySp} {
						width: 100px;
					}
				}
			}
		}

		+ .p-table{
			margin-top: 2em;
		}
	}
}
