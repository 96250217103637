@charset "UTF-8";
// -----------------------------------------------------------------
// Main
// - main
// -----------------------------------------------------------------

/*	Main
______________________________________________*/
.l-main {
	position: relative;
	z-index: 1;
}

.l-content {
	width: 100%;
}
.l-content__header {
	width: 100%;
	margin-top: 60px;
	&.position--fixed {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
	}
	@media #{$querySp} {
		margin-top: 15px;
	}
}
.l-content__body {
	margin-top: 100px;
	@media #{$querySp} {
		margin-top: 50px;
	}
}
.l-content__container {
	width: 100%;
	@include contGutter(115,0);
	max-width: 1280px;

	@media screen and (min-width: 768px) and (max-width: 1079px) {
		@include contGutter(85,0);
	}

	@media #{$querySp} {
		padding: 0 20px;
	}

	&.width--s {
		max-width: 960px;
	}
	&.width--l {
		max-width: 1560px;
	}
	&.width--xl {
		@include contGutter(0,0);
		max-width: initial;
	}
}
