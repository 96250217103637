@charset "UTF-8";
// -----------------------------------------------------------------
// Contact
// -----------------------------------------------------------------

.p-form {
	width: 100%;
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	textarea {
		color: #666;
		font-size: 1.6rem;
		width: 100%;
		padding: 22px;
		border: none;
		border-radius: 0;
		outline: none;
		background: #f5f5f5;
		border-radius: 6px;
		@include placeholder(#ccc);
		@media #{$querySp} {
			font-size: 1.3rem;
			padding: 10px;
			border-radius: 3px;
		}
	}
	input[type="text"],
	input[type="email"],
	input[type="tel"] {
		line-height: 1;
	}
	textarea {
		line-height: 1.75;
	}
	button,
	input[type="submit"] {
		color: #fff;
		font-size: 2rem;
		text-align: center;
		width: 100%;
		height: 90px;
		line-height: 90px;
		border-radius: 6px;
		appearance: none;
		padding: 0;
		border: none;
		outline: none;
		background: #666;
		cursor: pointer;
		@media #{$querySp} {
			font-size: 1.3rem;
			height: 45px;
			line-height: 45px;
			border-radius: 3px;
		}
	}
	.mw_wp_form .horizontal-item + .horizontal-item {
		margin-left: 0;
	}
	.mw_wp_form .horizontal-item {
		margin-right: 10px;
	}
	.mwform-checkbox-field {
		display: inline-block;
		@extend .c-fontDefault;
	}
	label {
		cursor: pointer;
	}
	// .mwform-checkbox-field-text {
	// 	margin-left: 5px;
	// }
	.mw_wp_form .error {
		font-size: 1.6rem;
		line-height: 1.75;
		padding: 1em 0.5em 0;
		@media #{$querySp} {
			font-size: 1.3rem;
		}
	}
}
.p-form__row {
	width: 100%;
	margin-top: 40px;
	@media #{$querySp} {
		margin-top: 30px;
	}
}
.p-form__header {
	width: 100%;
}
.p-form__body {
	@extend .c-fontDefault;
	width: 100%;
	margin-top: 15px;
	@media #{$querySp} {
		margin-top: 10px;
	}
}
.p-form__submit {
	width: 100%;
	margin-top: 40px;
	@media #{$querySp} {
		margin-top: 30px;
	}
}
.p-form__privarypolicy {
	margin-top: 20px;
	font-family: $font-min;
}

.p-form__header-title {
	font-family: $font-min;
	font-size: 2rem;

	.c-fontDefault {
		font-family: $font-family;
		font-size: 1.6rem;
	}
}
