@charset "UTF-8";
// -----------------------------------------------------------------
// Base
// - 基本設計のみ記述
// -----------------------------------------------------------------

/*---------------------------------------------------

	BASE

---------------------------------------------------*/

//base setting
html {
	font-size: 62.5%;
	-webkit-text-size-adjust: none;
}

html,body { height: 100%; }

body {
	width: 100%;
	// min-width: 1080px;
	font-family: $font-family;
	color: $color-black;
	position: relative;

	@media #{$querySp} {
		font-size: 1.4rem;
		min-width: 100%;
	}
}

// link
a {
	color: inherit;
	text-decoration: none;
	@include trans();
	&:link,
	&:visited,
	&:hover,
	&:active {
		outline: none;
	}
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
}
// svg
img[src$=".svg"] {
	width: 100%;
}

// box-sizing
*,
*::before,
*::after {
    box-sizing: border-box;
}

.inview,
.inviewlate {
	opacity: 0;
	transform: translateY(70px);
	transition-duration: .6s;
	transition-timing-function: ease-out;
}

.inview.view,
.inviewlate.view {
	opacity: 1;
	transition-duration: .6s;
	transform: translateY(0) !important;
}

.inviewlate.view {
	transition-delay: .25s;
}


/* ‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑
	icon fonts
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
@font-face {
	font-family: 'UPD';
	src:
	  url('../fonts/UPD.ttf?n1cp7') format('truetype'),
	  url('../fonts/UPD.woff?n1cp7') format('woff'),
	  url('../fonts/UPD.svg?n1cp7#UPD') format('svg');
	font-weight: normal;
	font-style: normal;
  }

  i {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'UPD' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }

  .icon-facebook:before {
	content: "\e900";
  }
  .icon-instagram:before {
	content: "\e901";
  }
  .icon-twitter:before {
	content: "\e902";
  }
