@charset "UTF-8";
// -----------------------------------------------------------------
// Archive
// -----------------------------------------------------------------

/* news archive
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.p-newsIndex {
	width: calc(100% + 15px);
	margin: -50px 0 0 -15px;
	@media #{$querySp} {
		width: 100%;
		margin-left: 0;
	}
}
.p-newsIndex__item {
	margin-top: 50px;
	padding-left: 15px;
	@media #{$querySp} {
		margin-top: 30px;
		padding-left: 0;
	}
}
.p-newsIndex__itemInner {
	display: block;
	width: 100%;
}
.p-newsIndex__thumb {
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
	padding-top: calc(426 / 633 * 100%);
	overflow: hidden;
	& > img {
		width: auto;
		// height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 1;
	}
	&::after {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background: rgba(#fff, 0.9);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		opacity: 0;
		transition: opacity .25s ease-out;
	}
}
.p-newsIndex__itemInner:hover {
	.p-newsIndex__thumb::after {
		opacity: 1;
	}
	@media #{$querySp} {
		opacity: 0;
	}
}
.p-newsIndex__text {
	width: 100%;
	padding: 30px 30px 0;
	@media #{$querySp} {
		padding: 15px 15px 0;
	}
}
.p-newsIndex__date {
	font-family: $font-min;
}
.p-newsIndex__title {}


/* projects archive
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.projectIndex {
	height: auto;
	min-height: 900px;
	// padding: 200px 0 120px;
	padding: 220px 0 120px;
	position: relative;
	.l-main {
		position: static;
	}
	.l-content {
		display: flex;
		align-items: center;
	}
	.l-content__header {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		margin-top: 0;
		padding: 60px 0;
		background: #fff;

		@media #{$querySp} {
			padding: 60px 0 20px;
		}
	}
	.l-content__body {
		width: 100%;
		margin: 0;
		position: relative;
		z-index: 1;
	}
	.l-footer {
		margin-top: 0;
		position: absolute;
		bottom: 0;
		left: 0;
	}
	@media #{$querySp} {
		min-height: initial;
		padding: 100px 0 60px;
	}
}
.p-projectIndex__container {
	width: 100%;
	position: relative;

	@media #{$querySp} {
		padding-top: 30px;
	}

}
.p-projectIndex__wrapper {
	width: 100%;
	height: calc(100% + 400px);
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	z-index: 10;
	// overflow: hidden;
	// overflow: visible;
	// overflow: scroll;
	// -webkit-overflow-scrolling: touch;
	@media #{$querySp} {
		height: 100%;
	}
}
.p-projectIndex {
	width: 100%;
	// height: calc(100% - 400px);
	overflow: visible;
	// transform: translateY(20px);
	// transition: opacity .3s, transform .3s;
	letter-spacing: -0.4em;

	@media #{$querySp} {
		overflow: hidden;
	}

	// &.is-active {
	// 	opacity: 1;
	// 	transform: translateY(0);
	// }

	.swiper-slide {
		width: 100%;
		display: flex;
		justify-content: space-between;
		pointer-events: none;
		opacity: 0;
		transform: translateY(20px);
		transition: opacity .3s, transform .3s;
	}
	.swiper-slide-next,
	.swiper-slide-next + .swiper-slide,
	.swiper-slide-active {
		pointer-events: auto;
		opacity: 1;
		transform: translateY(0);
	}
	.swiper-slide-prev {
		opacity: 0;
		transform: translateY(-20px);
	}
	@media #{$querySp} {
		height: calc(100% - 200px);
		top: 100px;
		transform: translateY(10px);
		.swiper-slide {
			transform: translateY(10px);
		}
		.swiper-slide-next,
		.swiper-slide-next + .swiper-slide,
		.swiper-slide-active {
			transform: translateY(0);
		}
		.swiper-slide-prev {
			transform: translateY(-10px);
		}
	}
}
.p-projectIndex__item {
	display: inline-block;
	letter-spacing: normal;

	@media ( min-width : 1200px ) {
		margin-bottom: 15px;
		width: calc((100% - 30px) / 3);

		+ .p-projectIndex__item{
			margin-left: 15px;
		}

		&:nth-child(3n+1){
			margin-left: 0;
		}
	}

	@media ( min-width : 768px ) {
		@media ( max-width : 1199px ) {
			width: calc((100% - 15px) / 2);

			&:nth-child(n+3){
				margin-top: 15px;
			}

			&:nth-child(odd){
				float: left;
			}
			&:nth-child(even){
				float: right;
			}
		}
	}

	@media #{$querySp} {
		width: 100%;
		text-align: center;

		+ .p-projectIndex__item{
			margin-top: .5em;
		}
	}
}
.p-projectIndex__itemInner {
	display: block;
	width: 100%;
	position: relative;
	overflow: hidden;
}
.p-projectIndex__fill {
	width: 100%;
	height: 100%;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(255,255,255, .55);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	opacity: 0;
	transition: opacity .25s ease-out;
	@media #{$querySp} {
		height: auto;
		// background: rgba(255,255,255, .55);
		padding: 10px;
		opacity: 1;
		top: auto;
		bottom: 0;
		transition: none;
	}
}
.p-projectIndex__itemInner:hover {
	.p-projectIndex__fill {
		opacity: 1;
	}
}
.p-projectIndex__title {
	font-family: $font-min;
	color: #666;
	font-size: 3.2rem;
	line-height: 1.3;
	margin-bottom: -5px;
	text-align: center;
	// @media #{$queryTab} {
	// 	font-size: 2.6rem;
	// }

	@media screen and (max-width: 1469px) {
		font-size: 2.9rem;
	}

	@media screen and (max-width: 1284px) {
		font-size: 2.7rem;
	}

	@media screen and (max-width: 1221px) {
		font-size: 2.5rem;
	}

	@media screen and (max-width: 1199px) {
		font-size: 3.2rem;
	}

	@media screen and (max-width: 999px) {
		font-size: 2.9rem;
	}

	@media screen and (max-width: 879px) {
		font-size: 2.5rem;
	}

	@media screen and (max-width: 799px) {
		font-size: 2.3rem;
	}

	@media #{$querySp} {
		font-size: 1.6rem;
	}
}
