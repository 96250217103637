@charset "UTF-8";
// -----------------------------------------------------------------
// Component
// - text
// -----------------------------------------------------------------

/* 罫線なし - aboutページで使用しているもの
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.c-tablePlain {
	width: 100%;
}
.c-tablePlain__row {
	display: flex;
	@media #{$querySp} {
		flex-direction: column;
		margin-top: 60px;
	}
}
.c-tablePlain__th {
	width: 270px;
	padding: 30px 30px 30px 0;
	@media #{$querySp} {
		width: 100%;
		padding: 0;
	}
}
.c-tablePlain__td {
	flex: 1;
	padding: 30px 0;
	@media #{$querySp} {
		width: 100%;
		padding: 0;
		margin-top: 15px;
	}
}
.c-tablePlain__row:first-child {
	.c-tablePlain__th {
		padding-top: 0;
	}
	.c-tablePlain__td {
		padding-top: 0;
	}
	@media #{$querySp} {
		.c-tablePlain__th {
			margin-top: 0;
		}
	}
}
.c-tablePlain__row:last-child {
	.c-tablePlain__th {
		padding-bottom: 0;
	}
	.c-tablePlain__td {
		padding-bottom: 0;
	}
}

/* 罫線あり - aboutページのprofileで使用しているもの
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.c-tableBordered {
	width: 100%;
}
.c-tableBordered__row {
	display: flex;
	border-bottom: 1px solid #e5e5e5;
	&:first-child {
		border-top: 1px solid #e5e5e5;
	}
}
.c-tableBordered__th {
	width: 170px;
	padding: 20px 20px 20px 25px;
	@media #{$querySp} {
		width: 35%;
		padding: 10px 10px 10px 0;
	}
}
.c-tableBordered__td {
	flex: 1;
	padding: 20px 0;
	@media #{$querySp} {
		padding: 10px 0;
	}
}

/* 罫線あり（大） - recruitページで使用しているもの
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.c-tableBordered.size--l {
	.c-tableBordered__row {
		@media #{$querySp} {
			flex-direction: column;
			padding: 20px 0;
		}
	}
	.c-tableBordered__th {
		// width: 260px;
		width: 20.313%;
		padding: 40px 40px 40px 0;
		@media #{$querySp} {
			width: 100%;
			padding: 0;
		}
	}
	.c-tableBordered__td {
		padding: 40px 0;
		@media #{$querySp} {
			margin-top: 20px;
			padding: 0;
		}
	}
}
