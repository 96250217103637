@charset "UTF-8";
// -----------------------------------------------------------------
// Variable
// - グローバル変数の定義
// -----------------------------------------------------------------

// Font Family -----------------------------------------------------
$font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック体", "Yu Gothic", "YuGothic", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font-min: 'Ovo', "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;

// path -----------------------------------------------------------
$imagePath: "../images/";


// Color ----------------------------------------------------------
// Brand Colors
$color-brand: #ff5a03;

// Neutral Colors
$color-white: #fff;
$color-black: #555;
$color-gray: #e5e5e5;
$color-gray-subtle: #eee;
$color-gray-light: #f5f5f5;

// Utility Colors
$color-utility-neutral: #0096ff;


// break point ----------------------------------------------------
$queryPc: "screen and (min-width: 768px)";//PC
$queryPcTab: "screen and (min-width:768px)";//PC&TAB
$queryTab: "screen and (max-width: 1023px)";//TAB
$querySp: "screen and (max-width: 767px)";//SP


// animation keyframe ----------------------------------------------------
