@charset "UTF-8";
// -----------------------------------------------------------------
// Component
// - font
// -----------------------------------------------------------------

/* headline
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.c-headlineLeader {
	color: #777;
	font-size: 2.4rem;
	line-height: 1.3;
	font-family: $font-min;
	position: relative;
	padding-left: 1.5em;

	&::before {
		content: "";
		width: 2.4rem;
		height: 1px;
		background-color: #777;
		position: absolute;
		top: .75em;
		left: 0;
	}
	@media #{$querySp} {
		font-size: 1.6rem;
		&::before {
			width: 1.6rem;
		}
	}
}

.c-font{
	font-family: $font-min;
}

/* text
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.c-fontDefault {
	// color: #777;
	color: #555;
	font-size: 1.6rem;
	letter-spacing: 0.05em;
	line-height: 1.75;
	@media #{$querySp} {
		font-size: 1.3rem;
	}
}

.c-fontStrong {
	color: #555;
	font-size: 1.6rem;
	font-weight: bold;
	letter-spacing: 0.05em;
	line-height: 1.75;
	@media #{$querySp} {
		font-size: 1.3rem;
	}
}

.c-fontLarge {
	// color: #666;
	color: #555;
	font-size: 2rem;
	font-weight: bold;
	letter-spacing: 0.05em;
	line-height: 1.5;
	@media #{$querySp} {
		font-size: 1.5rem;
	}
}
