@charset "UTF-8";
// -----------------------------------------------------------------
// Component
// - column
// -----------------------------------------------------------------

/* PC:3カラム / SP:1カラム - projectページで使用しているもの
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.c-culumn3 {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	& > .c-culumn__item {
		width: 33.3333%;
	}
}
.c-culumn2 {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	& > .c-culumn__item {
		width: 50%;
	}
}
.c-culumn1--sp {
	@media #{$querySp} {
		flex-direction: column;
		& > .c-culumn__item {
			width: 100%;
		}
	}
}