@charset "UTF-8";
// -----------------------------------------------------------------
// post single
// -----------------------------------------------------------------

/* news single
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.p-newsArticle {
	width: 100%;
}
.p-newsArticle__header {
	padding-bottom: 50px;
	border-bottom: 1px solid #e5e5e5;
	@media #{$querySp} {
		padding-bottom: 25px;
	}
}
.p-newsArticle__date {
	display: block;
	margin-bottom: 10px;
}
.p-newsArticle__title {
	// font-family: "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
	color: #555;
	font-size: 3.2rem;
	// font-weight: 600;
	// font-feature-settings : "palt" 1;
	line-height: 1.35;
	@media #{$querySp} {
		font-size: 2.2rem;
	}
}
.p-newsArticle__body {
	margin-top: 50px;
	@include clearfix;
	h2,
	h3,
	h4,
	h5 {
		font-weight: bold;
		line-height: 1.5;
		margin: 1em 0;
	}
	h2 {
		@extend .c-fontLarge;
	}
	p {
		@extend .c-fontDefault;
		@include clearfix;
		margin-bottom: 1em;
	}
	.wp-caption,
	p > img {
		display: block;
		margin-top: 40px;
		margin-bottom: 70px;
	}
	.wp-caption-text {
		color: #777;
		font-size: 1.4rem;
		font-style: italic;
		line-height: 1.5;
		margin-top: 10px;
		text-align: right;
	}
	.alignnone,
	.aligncenter {
		margin-left: auto;
		margin-right: auto;
	}
	.alignleft {
		float: left;
	}
	.alignright {
		float: right;
	}
	@media #{$querySp} {
		margin-top: 25px;
		.wp-caption,
		p > img {
			margin-top: 20px;
			margin-bottom: 30px;
		}
		.wp-caption-text {
			font-size: 1.2rem;
		}
	}
}
.p-newsArticle__footer {
	margin-top: 50px;
	padding-top: 30px;
	border-top: 1px solid #e5e5e5;
}
.p-newsArticle__pager {
	width: 100%;
	@include clearfix;
	// display: flex;
	// justify-content: space-between;
	@media #{$querySp} {
		// display: block;
		// @include clearfix;
	}
}
.p-newsArticle__prev,
.p-newsArticle__next {
	display: flex;
	align-items: center;
}
.p-newsArticle__prev {
	float: left;
	&::before {
		content: "";
		display: block;
		width: 16px;
		height: 16px;
		margin-right: 10px;
		transform: rotate(-45deg);
		border-top: 2px solid #666;
		border-left: 2px solid #666;
	}
	@media #{$querySp} {
		&::before {
			width: 8px;
			height: 8px;
		}
	}
}
.p-newsArticle__next {
	float: right;
	&::after {
		content: "";
		display: block;
		width: 16px;
		height: 16px;
		margin-left: 10px;
		transform: rotate(-45deg);
		border-right: 2px solid #666;
		border-bottom: 2px solid #666;
	}
	@media #{$querySp} {
		clear: both;
		margin-top: 15px;
		&::after {
			width: 8px;
			height: 8px;
		}
	}
}
.p-newsArticle__prev > a,
.p-newsArticle__next > a {
	color: #777;
	font-size: 1.6rem;
	letter-spacing: 0.05em;
	line-height: 1.5;
	// flex: 1;
	width: 20em;
	text-decoration: underline;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	&:hover {
		text-decoration: none;
	}
	@media #{$querySp} {
		font-size: 1.3rem;
		&:hover {
			text-decoration: underline;
		}
	}
}
.p-newsArticle__next > a {
	text-align: right;
}


/* projects single
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.p-projectArticle {
	width: 100%;
	* {
		color: #666;
	}
}
.p-projectArticle__slider {
	width: 100%;
	height: 600px;
	position: relative;
	.swiper-container {
		width: calc(100% - 150px);
		flex-basis: 100%;
		margin-left: 150px;
		padding-right: 60px;
	}
	.swiper-slide {
		width: auto;
		max-width: 1070px;
		height: 600px;
	}
	@media #{$querySp} {
		height: calc(600px / 4);
		.swiper-container {
			// width: calc(100% - 40px);
			width: 100%;
			// margin-left: 20px;
			margin: 0;
			padding: 0;
		}
		.swiper-slide {
			max-width: calc(1070px / 4);
			height: calc(600px / 4);
		}
	}
}
.p-projectArticle__slide {
	height: 100%;
}
.p-projectArticle__figure {
	height: 100%;
	position: relative;
	cursor: pointer;
}
.p-projectArticle__img {
	height: 100%;
	& > img {
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
	}
}
.p-projectArticle__more {
	// color: #666;
	font-family: $font-min;
	font-size: 3.6rem;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255, .55);
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	transition: opacity .25s ease-out;
	&:hover {
		opacity: 1;
	}
	@media #{$querySp} {
		display: none;
	}
}
.p-projectArticle__prev,
.p-projectArticle__next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.p-projectArticle__prev {
	left: 0;
}
.p-projectArticle__next {
	right: 0;
}
.p-projectArticle__body {
	width: 100%;
	margin-top: 50px;
	padding: 0 150px;
	// @media #{$queryTab} {
	// 	padding-left: 120px;
	// 	padding-right: 30px;
	// }
	@media #{$querySp} {
		margin-top: 25px;
		// padding: 0 20px;
		padding: 0;
	}
}
// .p-projectArticle__container {
// 	max-width: 1070px;
// 	@media #{$querySp} {
// 		max-width: calc(1070px / 4);
// 	}
// }

.p-headline{
	font-size: 1.8rem;
	font-family: $font-min;
	@media #{$querySp} {
		font-size: 1.6rem;
	}
}
.p-projectArticle__headline {
	margin-bottom: 60px;
	font-family: $font-min;
	// font-size: 5.6rem;
	font-size: 3.8rem;
	line-height: 1.5;

	@media #{$querySp} {
		margin-bottom: 30px;
		font-size: 2.2rem;
	}

	.p-span{
		display: block;
		font-size: 1.8rem;

		@media #{$querySp} {
			font-size: 1.6rem;
		}
	}
}
.p-projectArticle__bodyInner {
	width: 100%;
	margin-top: 30px;
	// display: flex;
	@media #{$querySp} {
		margin-top: 15px;
		flex-direction: column;
	}
}
.p-projectArticle__summary {
	margin-top: 33px;
	// width: calc(525 / 1070 * 100%);
	@media #{$querySp} {
		margin-top: 16px;
		// width: 100%;
	}
}
.p-projectArticle__summaryList {

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

.p-projectArticle__copy {
	flex: 1;
	@media #{$querySp} {
		width: 100%;
		margin-top: 15px;
	}
}
.p-projectArticle__copy {
	p{
		+ p{
			margin-top: 38px;
			@media #{$querySp} {
				margin-top: 19px;
			}
		}
	}
}

.p-projectArticle__copyBody {
	font-size: 1.6rem;
	letter-spacing: .05em;
	line-height: 1.75;
	@media #{$querySp} {
		font-size: 1.3rem;
	}
}

.p-projectArticle__info {
	font-size: 1.8rem;
	font-weight: 600;
	letter-spacing: .05em;
	line-height: 1.75;

	@media #{$querySp} {
		font-size: 1.5rem;
	}
}

.p-projectArticle__works {
	font-size: 1.6rem;
	letter-spacing: .05em;
	line-height: 1.75;
	margin: 33px 0 0;

	@media #{$querySp} {
		font-size: 1.3rem;
		margin: 16px 0 0;
	}
}

.p-projectArticle__modal {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	// position: relative;
	z-index: 100;
	transform: translateY(-100%);
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	transition:
		opacity .4s,
		transform 0s .4s,
		height 0s .4s,
		visibility 0s .4s;
	&.is-active {
		transform: translateY(0);
		visibility: visible;
		opacity: 1;
		pointer-events: auto;
		transition:
			opacity .4s,
			transform 0s,
			height 0s,
			visibility 0s;
	}
	.swiper-container {
		width: 100%;
		height: 100%;
	}

	.swiper-slide {
		height: 90%;
		margin: auto;

		// @media #{$querySp} {
		// 	height: 100%;
		// }
	}
}
.p-projectArticle__modalBackground {
	width: 100%;
	height: 100%;
	position: absolute;
	background: #fff;
	z-index: 1;
}
.p-projectArticle__modalClose {
	width: 38px;
	height: 38px;
	position: absolute;
	// top: 60px;
	// right: 60px;
	top: 30px;
	right: 30px;
	z-index: 20;
	cursor: pointer;
	&::before,
	&::after {
		content: "";
		background: #555;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%) rotate(45deg);
	}
	&::before {
		width: 50px;
		height: 2px;
	}
	&::after {
		width: 2px;
		height: 50px;
	}
	@media #{$querySp} {
		width: 30px;
		height: 30px;
		top: 15px;
		right: 15px;
		&::before {
			width: 40px;
			height: 1px;
		}
		&::after {
			width: 1px;
			height: 40px;
		}
	}
}
.p-projectArticle__modalSlide {
	width: 100%;
	height: 100%;
}
.p-projectArticle__modalImg {
	// width: 100vw;
	width: calc(100% - 120px);
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	margin: 0 auto;

	@media #{$querySp} {
		// width: 100vw;
		width: calc(100% - 60px);
		// height: 100vh;
	}
}
.p-projectArticle__modalPrev,
.p-projectArticle__modalNext {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.p-projectArticle__modalPrev {
	left: 0;
}
.p-projectArticle__modalNext {
	right: 0;
}

.p-pager{
	margin-top: 55px;
	font-size: 1.8rem;
	font-family: $font-min;
	border-top: 1px solid #e5e5e5;

	@media ( max-width : 767px ) {
		margin-top: 27px;
		font-size: 1.4rem;
		white-space: nowrap;
	}

	.p-pager__item{
		display: inline-block;
		padding-top: 60px;
		width: calc(100% / 3.1);

		@media ( max-width : 767px ) {
			padding-top: 30px;
		}

		&--prev,
		&--next {
			.p-pager__nav{
				position: relative;

				&:before{
					display: block;
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					width: 10px;
					height: 10px;
					margin: auto;
					border-top: 2px solid #555;
					border-right: 2px solid #555;
				}

				@media ( max-width : 767px ) {
					span{
						display: none;
					}
				}
			}
		}

		&--prev {
			.p-pager__nav{
				padding-left: 24px;

				&:before{
					left: 0;
					transform: translate(0, 0) rotate(-135deg);
				}
			}
		}

		&--archive {
			text-align: center;
		}

		&--next {
			text-align: right;

			.p-pager__nav{
				padding-right: 24px;

				&:before{
					right: 0;
					transform: translate(0, 0) rotate(45deg);
				}
			}
		}
	}
}
