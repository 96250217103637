@charset "UTF-8";
// -----------------------------------------------------------------
// Header
// - headerのみ
// -----------------------------------------------------------------

/*	Header
______________________________________________*/
.l-header {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	background: #fff;
	@media #{$querySp} {
	}
}
.l-header__button {
	position: absolute;
	top: 60px;
	left: 60px;
	// @media #{$queryTab} {
	// 	top: 30px;
	// 	left: 30px;
	// }

	@media screen and (min-width: 768px) and (max-width: 1079px) {
		left: 30px;
	}

	@media #{$querySp} {
		top: 15px;
		left: 15px;
	}
}

/* globalmenu
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/

.l-globalMenu {
	width: 100vw;
	height: 0;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 900;
	background: #fff;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	transition:
		opacity .4s,
		height 0s .4s,
		visibility 0s .4s;
	&.is-active {
		height: 100vh;
		visibility: visible;
		// opacity: 1;
		opacity: 0.95;
		pointer-events: auto;
		transition:
			opacity .4s,
			height 0s,
			visibility 0s;
	}
}
.l-globalMenu__container {
	width: 100%;
	height: auto;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	justify-content: center;
	// max-height: calc(100vh - 200px);
	// position: absolute;
	// top: 50%;
	// transform: translateY(-50%);
	// overflow-y: scroll;
	// -webkit-overflow-scrolling: touch;
	@media #{$querySp} {
		max-height: calc(100vh - 80px);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
}
.l-globalMenu__inner {
	display: inline-block;
	// width: 506px;
	// margin: 0 auto;
	@media #{$querySp} {
		display: block;
		width: 250px;
		margin: 0 auto;
	}
}
.l-globalMenu__header {
	display: block;
	text-align: center;
	width: 100%;
	// padding-bottom: 55px;
	padding-bottom: calc(55 / 1440 * 100vh);
	border-bottom: 1px solid #666;
	@media #{$querySp} {
		padding-bottom: 25px;
	}
}
.l-globalMenu__logo {
	display: inline-block;
	// display: block;
	// width: 160px;
	height: calc(83 / 1440 * 100vh);
	// margin: 0 auto;
	img {
		// width: 100%;
		// height: auto;
		width: auto;
		height: 100%;
	}
	@media #{$querySp} {
		display: block;
		width: 80px;
		height: auto;
		margin: 0 auto;
		img {
			width: 100%;
			height: auto;
		}
	}
}
.l-globalMenu__body {
	// padding: 55px 0;
	padding-top: calc(55 / 1440 * 100vh);
	padding-bottom: calc(55 / 1440 * 100vh);
	@media #{$querySp} {
		padding: 25px 0;
	}
}
.l-globalMenu__list {
	line-height: 1;
}
.l-globalMenu__item {
	// margin: 42px 0 0;
	margin-top: calc(42 / 1440 * 100vh);
	text-align: center;
	&:first-child {
		margin-top: 0;
	}
	& > a {
		color: #666;
		font-family: $font-min;
		// font-size: 5.6rem;
		font-size: 56 / 1440 * 100vh;
		// margin-left: 1.68em;
		// margin-right: 1.68em;
		margin-left: 1.2em;
		margin-right: 1.2em;
		@include hoverColor;
	}
	&.is-current {
		& > a {
			color: #ccc;
		}
	}
	@media #{$querySp} {
		margin: 20px 0 0;
		& > a {
			font-size: 2.2rem;
			margin: 0;
		}
	}
}
.l-globalMenu__footer {
	width: 100%;
	// padding-top: 55px;
	padding-top: calc(55 / 1440 * 100vh);
	border-top: 1px solid #666;
	@media #{$querySp} {
		padding-top: 25px;
	}
}
.l-globalMenu__sns {
	line-height: 1;
	display: flex;
	justify-content: center;
	& > a {
		color: #777;
		// font-size: 56px;
		font-size: 56 / 1440 * 100vh;
		display: inline-block;
		// margin: 0 15px;
		margin-left: 0.27em;
		margin-right: 0.27em;
		@include hoverColor;
	}
	@media #{$querySp} {
		& > a {
			font-size: 22px;
			margin: 0 10px;
		}
	}
}
