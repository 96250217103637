@charset "UTF-8";
// -----------------------------------------------------------------
// Mixin
// - mixin定義
// -----------------------------------------------------------------



// flexbox
@mixin flex {
	-js-display: flex;
	display: flex;
}

// calc
@mixin calc($property, $expression) {
	#{$property}: -moz-calc(#{$expression});
	#{$property}: -o-calc(#{$expression});
	#{$property}: -webkit-calc(#{$expression});
	#{$property}: calc(#{$expression});
}

// box-shadow
@mixin boxShadow($blur,$alpha) {
	box-shadow: 0 2px $blur 0 rgba(0, 0, 0, $alpha);
}

// opacity
@mixin opacity($opacity) {
	opacity: $opacity;
	$opacityIE: $opacity * 100;
	filter: alpha(opacity=$opacityIE);
}

// border radius
@mixin borderRadius($size) {
	border-radius: $size;
}

// transition
@mixin trans($duration: .2s,$delay: 0s) {
	transition: all $duration ease-in-out $delay;
}

// transform
@mixin transform($transform) {
	transform: $transform;
}

// scale
@mixin scale($scale) {
	@include transform(scale($scale));
}

// rotate
@mixin rotate($rotate) {
	@include transform(rotate($rotate));
}

// placeholder
@mixin placeholder($color) {
	&::-webkit-input-placeholder {
		color:$color;
	}
	&:-moz-placeholder {
		color:$color;
	}
	&:-ms-input-placeholder {
		color:$color;
	}
}

// clearfix
@mixin clearfix {
	&:after {
		content:" ";
		display:block;
		clear:both;
	}
}

/* hover animation
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
// opacity
@mixin hoverOpacity($val: 0.6) {
	@media #{$queryPc} {
		transition: opacity .2s ease-out;
		opacity: 1;
		&:hover {
			opacity: $val;
		}
	}
}

// color
@mixin hoverColor($val: #ccc) {
	@media #{$queryPc} {
		transition: color .2s ease-out;
		&:hover {
			color: $val;
		}
	}
}

/* others
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
// container gutter
@mixin contGutter($gutterPC: 60, $gutterSP: 15) {
	@if $gutterPC == 0 {
		width: 100%;
	} @else {
		$gutterPC: $gutterPC * 2 + px;
		width: calc(100% - #{$gutterPC});
	}
	margin: 0 auto;
	// @media #{$queryTab} {
	// 	@if $gutterTAB == 0 {
	// 		// min-width: 1080px;
	// 		width: 100%;
	// 	} @else {
	// 		$gutterTAB: $gutterTAB * 2 + px;
	// 		// min-width: calc(1080px - #{$gutterTAB});
	// 		width: calc(100% - #{$gutterTAB});
	// 	}
	// }
	@media #{$querySp} {
		@if $gutterSP == 0 {
			// min-width: 1080px;
			width: 100%;
		} @else {
			$gutterSP: $gutterSP * 2 + px;
			// min-width: calc(1080px - #{$gutterSP});
			width: calc(100% - #{$gutterSP});
		}
	}
}
