@charset "UTF-8";
// -----------------------------------------------------------------
// Project
// - top
// -----------------------------------------------------------------

.p-home {
	width: 100vw;
	min-width: 1080px;
	height: 100vh;
	position: relative;
	@media #{$querySp} {
		min-width: initial;
	}
}
.p-home__background {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;

	&:after {
		content: "";
		background: rgba(255,255,255,0.85);
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}
}
// .p-home__slider {
// 	.swiper-slide-active,
// 	.swiper-slide-prev,
// 	.swiper-slide-duplicate-active {
// 		& > .p-home__slide {
// 			transform: scale(1.1);
// 		}
// 	}
// 	.swiper-slide-next {
// 		& > .p-home__slide {
// 			transition: none;
// 			transform: scale(1);
// 		}
// 	}
// }
.p-home__slide {
	width: 100vw;
	min-width: 1080px;
	height: 100vh;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	// transform: scale(1);
	// transition: transform 6s linear;
	@media #{$querySp} {
		min-width: initial;
	}
}
.p-home__logo {
	width: calc(852 / 2560 * 100%);
	min-width: 426px;
	position: absolute;
	top: 44.2%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: 10;
	& > img {
		width: 100%;
		height: auto;
	}
	@media #{$querySp} {
		width: calc(210 / 320 * 100%);
		min-width: initial;
		max-width: 300px;
	}
}
