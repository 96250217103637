@charset "UTF-8";
// -----------------------------------------------------------------
// Recruit
// -----------------------------------------------------------------



.p-recruitTable {
	width: 100%;
	margin-top: 30px;

	* {
		color: #555;
	}
}
.p-applicationTable {
	width: 100%;
}
.p-applicationTable__row {
	width: 100%;
	margin-top: 40px;
	display: flex;
	&:first-child {
		margin-top: 0;
	}
	@media #{$querySp} {
		margin-top: 20px;
	}
}
.p-applicationTable__th {
	font-family: $font-min;
	width: 90px;
	@media #{$querySp} {
		width: 70px;
	}

	.c-fontStrong {
		font-size: 1.8rem;
	}
}
.p-applicationTable__td {
	flex: 1;
}
