@charset "UTF-8";
// -----------------------------------------------------------------
// Services
// -----------------------------------------------------------------

.p-services {

	* {
		color: #555;
	}

	@media #{$querySp} {
	}
}

.p-servicesBox {
	margin: 40px 0;

	@media #{$querySp} {
		margin: 25px 0;
	}
}

.p-services__title01,
.p-services__title02 {
	font-family: $font-min;
}

.p-services__title01 {
	font-size: 2.4rem;
	margin-bottom: 40px;

	@media #{$querySp} {
		font-size: 1.9rem;
		margin-bottom: 25px;
	}

	.c-fontDefault {
		font-family: $font-family;
		font-size: 1.7rem;

		@media #{$querySp} {
			font-size: 1.5rem;
		}
	}
}

.p-services__title02 {
	font-size: 2rem;
	margin: 40px 0 20px;

	@media #{$querySp} {
		font-size: 1.8rem;
		margin: 25px 0 10px;
	}
}

.p-services__list {
	// @include clearfix;
	// margin: 20px 0;
	// display: flex;
	// justify-content: space-between;
	// letter-spacing: -0.4em;

	@media #{$querySp} {
		// margin: 10px 0;
	}
}

.p-services__item {
	// float: left;
	// width: 18.75%;
	// display: flex;
	// justify-content: space-between;
	// letter-spacing: normal;
	// vertical-align: top;
	// height: 110px;

	// @media screen and (max-width: 1152px) {
	// 	height: 100px;
	// }

	// @media screen and (max-width: 1008px) {
	// 	height: 90px;
	// }

	// @media screen and (max-width: 924px) {
	// 	height: 80px;
	// }

	// @media screen and (max-width: 840px) {
	// 	height: 70px;
	// }

	@media #{$querySp} {
		// float: none;
		// display: block;
		// height: auto;
		// width: 100%;
	}

	// &:not(:last-child) {
	// 	margin-right: 1.5625%;

	// 	@media #{$querySp} {
	// 		margin-right: 0;
	// 		margin-bottom: 15px;
	// 	}
	// }

	// img {
	// 	height: 100%;

	// 	@media #{$querySp} {
	// 		width: 100%;
	// 	}
	// }
}

.p-servicesBlock01 {

	.p-services__list {
		margin: 20px -1%;
		display: flex;
		justify-content: space-between;
	}

	.p-services__item {
		margin: 0 1%;
	}
}

.p-servicesBlock02 {

	.p-services__list {
		margin: 20px -1%;
		display: flex;
		justify-content: space-between;
	}

	.p-services__item {
		margin: 0 1%;
	}
}

.p-servicesBlock03 {

	.p-services__list {
		letter-spacing: -0.4em;
	}

	.p-services__item {
		margin: 20px 0;
		display: inline-block;
		letter-spacing: normal;
		vertical-align: top;
		width: 23.828125%;

		&:not(:last-child) {
			margin-right: 1.5625%;
		}
	}
}

.p-services__info {
	font-size: 1.4rem;
}

.p-servicesOutro {
	margin-top: 80px;

	@media #{$querySp} {
		margin-top: 60px;
	}
}
