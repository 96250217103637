@charset "UTF-8";
// -----------------------------------------------------------------
// Project
// - page
// -----------------------------------------------------------------

/* pagetitle
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.p-pageTitle {
	width: 100%;
	text-align: center;
	// @media #{$queryTab} {
	// 	margin-top: 30px;
	// }
}
.p-pageTitle__logo {
	display: inline-block;
	width: 100px;
	& > a {
		display: inline-block;
	}
	img {
		width: 100%;
		height: auto;
	}
	@media #{$querySp} {
		width: 50px;
	}
}
.p-pageTitle__title {
	color: #666;
	font-size: 3.2rem;
	font-family: $font-min;
	line-height: 1;
	margin-top: 15px;
	@media #{$querySp} {
		font-size: 1.6rem;
		margin-top: 10px;
	}
}
.p-pageTitle.size--l {
	& > .p-pageTitle__logo {
		width: 506px;
		padding-bottom: 40px;
		text-align: center;
		border-bottom: 1px solid #666;
		img {
			width: 160px;
			height: auto;
		}
		@media #{$querySp} {
			width: 200px;
			padding-bottom: 20px;
			img {
				width: 80px;
			}
		}
	}
	& > .p-pageTitle__title {
		font-size: 5.6rem;
		margin-top: 40px;
		@media #{$querySp} {
			font-size: 2.2rem;
			margin-top: 20px;
		}
	}
}
