@charset "UTF-8";
// -----------------------------------------------------------------
// Component
// - list
// -----------------------------------------------------------------

/* ordered list
‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑‑*/
.c-orderedList {
	counter-reset: item;
	ol {
		padding-left: 0.5em;
	}
	li {
		text-indent: -1.5em;
		padding-left: 1.5em;
	}
	li:before {
		counter-increment: item;
		content: counter(item)'.';
		padding-right: .5em;
		color: #777;
	}
}
